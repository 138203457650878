import apiClient from "@/entrypoints/frontend/apiClient";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import { useHandRaiseStore } from "@/entrypoints/stores/hand_raise";
import { defineStore } from "pinia";
import { ref, computed, reactive } from "vue";
import { ElectionChannelSubscription, electionChannelSubscription } from "@/channels/ElectionChannel";
import { shuffleWithSeed } from '@/lib/ShuffleWithSeed';

import type {
  VoterCounts,
  Toast,
  Slide,
  LiveTab,
  ConferenceContest,
  ConferenceOption,
  ConferenceVotingRound,
  ConferenceVotingRoundReport,
  Content,
  ContentPath,
  HandRaiseOrchestrator,
  LatestConfigurationConfig,
  SlideResult,
  UserPost,
  BallotStates,
} from "@/types";

export const usePresentationStore = defineStore("presentation", () => {
  const sharedStore = useSharedStore();
  const votingSessionStore = useVotingSessionStore();
  const votingModulesStore = useVotingModulesStore();
  const handRaiseStore = useHandRaiseStore();

  // State
  const readonly = ref<boolean>(false);
  const slides = ref<Slide[]>([]);
  const activeSlideId = ref<number>(null);
  const visibleTab = ref<LiveTab>("Presentation");
  const slideOffset = ref<number>(0); // Used for determining which way to animate slides
  const contests = ref<ConferenceContest[]>([]);
  const votingRounds = ref<ConferenceVotingRound[]>([]);
  const votingRound = ref<ConferenceVotingRound>(null);
  const votingRoundReports = ref<ConferenceVotingRoundReport[]>([]);
  const latestConfig = ref<LatestConfigurationConfig>(null);
  const voterCounts = reactive<VoterCounts>({
    totalVoters: 0,
    activeVoters: 0,
    totalEligibles: 0,
    activeEligibles: 0,
    activeEligiblesOrVoted: 0,
    totalEligiblesWeight: 0,
    activeEligiblesWeight: 0,
    activeEligiblesOrVotedWeight: 0,
    voted: 0,
    votedWeight: 0,
  });
  const toasts = ref<Toast[]>([]);
  const toastId = ref<number>(0);

  // Getters
  const activeSlide = computed<Slide>(() => {
    if (slides.value === null || !slides.value.length) return null;
    return slides.value.find((slide: Slide) => slide.id === activeSlideId.value);
  });

  const activeSlideVotingRound = computed<ConferenceVotingRound>(() => {
    if (activeSlide.value === null) return null;
    const currentVotingRound = votingRounds.value.find((vr: ConferenceVotingRound) =>
      vr.reference === activeSlide.value.votingRoundReference);
    return currentVotingRound;
  });

  const contestsInActiveVotingRound = computed<ConferenceContest[]>(() => {
    return contests.value.filter(
      (contest: ConferenceContest) =>
        activeSlideVotingRound.value.contestReferences.includes(contest.reference)
    );
  });

  const forceFullSizeSlide = computed<boolean>(() => 
    !!activeHandRaiseOrchestrator.value
    && activeSlide.value.observerLayout === "all_visible"
    && activeSlide.value.state === "open"
  );

  const activeHandRaiseOrchestrator = computed<HandRaiseOrchestrator>(() => 
    handRaiseStore.handRaiseOrchestrators.find((orchestrator: HandRaiseOrchestrator) =>
      orchestrator.votingRoundReference === activeSlide.value.votingRoundReference
    )
  );

  // Actions
  const setReadonly = (payload: boolean) => readonly.value = payload;

  const processOptions = (options: ConferenceOption[], randomize: boolean = false, seed: string) => {
    if (!randomize) return options;

    const newOptions = [...shuffleWithSeed(options, seed)]

    newOptions.forEach((option: ConferenceOption) => {
      if (option.children.length > 1) {
        option.children = processOptions(option.children, option.randomizeChildren, seed)
      }
    })
    return newOptions;
  }
  const setContests = (payload: ConferenceContest[]) =>  {
    const newContests = [...payload]
    const seed = `shuffled-${votingSessionStore.voter.identifier}`
    newContests.forEach((contest: ConferenceContest) => contest.options = processOptions(contest.options, contest.randomizeOptions, seed))

    contests.value = newContests
  }

  const setSlides = (payload: Slide[]) => slides.value = payload;

  const setVoterCounts = (payload: VoterCounts) => {
    if (payload.activeEligibles)
      voterCounts.activeEligibles = payload.activeEligibles;
    if (payload.activeEligiblesOrVoted)
      voterCounts.activeEligiblesOrVoted = payload.activeEligiblesOrVoted;
    if (payload.activeEligiblesOrVotedWeight)
      voterCounts.activeEligiblesOrVotedWeight = payload.activeEligiblesOrVotedWeight;
    if (payload.activeEligiblesWeight)
      voterCounts.activeEligiblesWeight = payload.activeEligiblesWeight;
    if (payload.activeVoters)
      voterCounts.activeVoters = payload.activeVoters;
    if (payload.totalEligibles)
      voterCounts.totalEligibles = payload.totalEligibles;
    if (payload.totalEligiblesWeight)
      voterCounts.totalEligiblesWeight = payload.totalEligiblesWeight;
    if (payload.totalVoters)
      voterCounts.totalVoters = payload.totalVoters;
    if (payload.voted)
      voterCounts.voted = payload.voted;
    if (payload.votedWeight)
      voterCounts.votedWeight = payload.votedWeight;
  }

  const setVisibleTab = (payload: LiveTab) => visibleTab.value = payload;

  const setBallotState = (ballotId: number, ballotState: BallotStates) => {
    const ballot = slides.value[slides.value.findIndex((slide: Slide) => slide.id === ballotId)];
    ballot.state = ballotState;
  }

  const setProgress = (ballotId: number, progress: Slide) => {
    let ballot = slides.value.find((slide: Slide) => slide.id === activeSlideId.value);
    if (ballot?.id !== ballotId ) return;
    ballot = Object.assign(ballot, progress);
  }

  const setResult = (ballotId: number, result: SlideResult) => {
    const ballot = slides.value[slides.value.findIndex((slide: Slide) => slide.id === ballotId)];
    ballot.result = result;
  }

  const resetBallot = (ballotId: number) => {
    const ballot = slides.value[slides.value.findIndex((slide: Slide) => slide.id === ballotId)];
    if (votingSessionStore.voting) {
      const votedOn = votingSessionStore.voter?.votedOn;
      votedOn.splice(votedOn.indexOf(ballotId.toString()), 1);
    }
    ballot.result = null;
    ballot.voteCount = 0;
    ballot.votesCombinedWeight = 0;
  }

  const subscribeToElectionChannel = (live: boolean = false) => {
    electionChannelSubscription.value = new ElectionChannelSubscription(
      { electionId: sharedStore.election.id },
      {
        connected() {
          updateStatus();
        },
        received(data: any) {
          switch (data.type) {
            case "goto":
              goToSlide(data.id);
              break;
            case "create_item":
              createItem(data);
              break;
            case "update_item":
              if (data.path.type === "votingRoundReport" && (data.changes.publishedAt || live)) {
                if (live) {
                  apiClient.get(`../voting_rounds/${data.path.voting_round_id}/voting_round_reports/${data.path.id}.json`)
                    .then(res => {
                      updateItem({ path: data.path, changes: res.data });
                    })
                } else {
                  fetchResult(data.path);
                }
              } else {
                updateItem({ path: data.path, changes: data.changes });
              }
              break;
            case "delete_item":
              deleteItem(data.path);
              break;
            case "state_change":
              setBallotState(data.ballot_id, data.state);
              break;
            case "progress":
              setProgress(data.ballot_id, data.progress);
              break;
            case "result_ready":
              if (live) fetchLiveResult(data.ballot_id);
              break;
            case "reset_ballot":
              resetBallot(data.ballot_id);
              votingSessionStore.setRedoVote(false);
              break;
            case "update":
              votingModulesStore.updatePosts();
              updateStatus(live);
              break;
            case "update_counts":
              setVoterCounts(data.voterCounts);
              if (live) ensureLastUpdateCounts(data.force);
              break;
            default:
              console.debug(`received unknown message ${data.type}; updating election`);
              updateStatus(live);
              break;
          }
        }
      }
    );
  }

  const createItem = ({ path, item }: Content) => {
    switch (path.type) {
      case "slide":
        slides.value.push(item);
        break;
      case "option":
        const parentItem = contests.value.find((contest: ConferenceContest) => contest.id === path.contest_id);
        const ancestryArray = path.ancestry?.split("/")?.filter((s: string) =>
          s !== "")?.map((s: string) => { return parseInt(s) });

        if (!ancestryArray.length) {
          contests.value.find((contest: ConferenceContest) =>
            contest.id === path.contest_id).options.push(item);
        } else {
          let newParent = parentItem
          ancestryArray.forEach((id: number) => {
            if (newParent.options) {
              newParent = newParent.options.find((option: ConferenceOption) => option.id === id);
            } else {
              newParent = newParent.children.find((option: ConferenceOption) => option.id === id);
            }
          });

          newParent.children.push(item);
        }

        const duplicate = contests.value.slice();
        contests.value = duplicate;
        break;
      case "post":
        if (visibleTab.value === "Comment" && item.type === "Comment") item.unread = false;
        votingModulesStore.posts.push(item);
        break;
      case "votingRound":
        votingRounds.value.push(item);
        break;
      case "votingRoundReport":
        votingRoundReports.value.push(item);
        break;
      case "handRaiseOrchestrator":
        handRaiseStore.addHandRaiseOrchestrator(item);
        break;
      case "handRaiseVote":
        handRaiseStore.addHandRaiseVote(item, path);
        break;
    }
  }

  const updateItem = ({ path, changes }: Content) => {
    let storeItem = null;
    let parentItem = null;

    switch (path.type) {
      case "slide":
        storeItem = slides.value.find((slide: Slide) => slide.id === path.id);
        storeItem = Object.assign(storeItem, changes);
        if (changes.position !== undefined) slides.value.sort((a, b) => a.position - b.position);
        break;
      case "option":
        parentItem = contests.value.find((contest: ConferenceContest) => contest.id === path.contest_id);

        const ancestryArray = path.ancestry?.split("/")?.filter((s: string) =>
          s !== "")?.map((s: string) => { return parseInt(s) })

        if (!ancestryArray.length) {
          storeItem = contests.value.find((contest: any) =>
            contest.id === path.contest_id).options.find((option: ConferenceOption) => option.id === path.id);
          Object.assign(storeItem, changes)
          if (changes.position !== undefined) {
            contests.value.find((contest: ConferenceContest) =>
              contest.id === path.contest_id).options.sort((a: ConferenceOption, b: ConferenceOption) =>
                a.position - b.position);
          }
        } else {
          let newParent = parentItem;
          ancestryArray.forEach((id: number) => {
            if (newParent.options) {
              newParent = newParent.options.find((option: ConferenceOption) => option.id === id);
            } else {
              newParent = newParent.children.find((option: ConferenceOption) => option.id === id);
            }
          })

          storeItem = newParent.children.find((option: ConferenceOption) => option.id = path.id);
          Object.assign(storeItem, changes);
          if (changes.position !== undefined)
            newParent.children.sort((a: ConferenceOption, b: ConferenceOption) =>a.position - b.position);
        }

        const duplicate = contests.value.slice();
        contests.value = duplicate;
        break;
      case "contest":
        storeItem = contests.value.find((contest: ConferenceContest) => contest.id === path.id);
        storeItem = Object.assign(storeItem, changes);
        break;
      case "post":
        storeItem = votingModulesStore.posts.find((post: UserPost) => post.id === path.id);
        storeItem = Object.assign(storeItem, changes);
        break;
      case "votingRound":
        storeItem = votingRounds.value.find((votingRound: ConferenceVotingRound) => votingRound.id === path.id);
        if (changes.ownerId) {
          const newChanges = { votingRoundReference: storeItem.reference }
          const slide = slides.value.find((slide: Slide) => slide.id === changes.ownerId)
          Object.assign(slide, newChanges)
        }
        if (storeItem) storeItem = Object.assign(storeItem, changes);
        break;
      case "votingRoundReport":
        storeItem = votingRoundReports.value.find((votingRoundReport: ConferenceVotingRoundReport) => votingRoundReport.id === path.id);
        storeItem = Object.assign(storeItem, changes);
        break;
      case "highlight":
        votingModulesStore.setActiveHighlight(changes);
        break;
      case "handRaiseOrchestrator":
        handRaiseStore.updateHandRaiseOrchestrator(changes, path);
        break;
      case "handRaiseVote":
        handRaiseStore.updateHandRaiseVote(changes, path);
        break;
    }
  }

  const deleteItem = (path: ContentPath) => {
    let itemIndex = null;
    let parentItem = null;

    switch (path.type) {
      case "slide":
        itemIndex = slides.value.findIndex((slide: Slide) => slide.id === path.id);
        if (~itemIndex) slides.value.splice(itemIndex, 1);
        break;
      case "option":
        parentItem = contests.value.find((contest: ConferenceContest) => contest.id === path.contest_id);
        if (!parentItem) break;

        const ancestryArray = path.ancestry?.split("/")?.filter((s: string) =>
          s !== "")?.map((s: string) => { return parseInt(s) });

        if (!ancestryArray.length) {
          itemIndex = parentItem.options.findIndex((option: ConferenceOption) => option.id === path.id);
          if (~itemIndex) parentItem.options.splice(itemIndex, 1);
        } else {
          let newParent = parentItem;
          ancestryArray.forEach((id: number) => {
            if (newParent.options) {
              newParent = newParent.options.find((option: ConferenceOption) => option.id === id);
            } else {
              newParent = newParent.children.find((option: ConferenceOption) => option.id === id);
            }
          });

          newParent.children.findIndex(option => option.id === path.id)
          if (~itemIndex) newParent.children.splice(itemIndex, 1)
        }

        const duplicate = contests.value.slice();
        contests.value = duplicate;
        break;
      case "post":
        itemIndex = votingModulesStore.posts.findIndex((post: UserPost) =>
          post.id === path.id);
        if (~itemIndex) votingModulesStore.posts.splice(itemIndex, 1);
        break;
      case "votingRound":
        itemIndex = votingRounds.value.findIndex((votingRound: ConferenceVotingRound) =>
          votingRound.id === path.id);
        if (~itemIndex) votingRounds.value.splice(itemIndex, 1);
        break;
      case "votingRoundReport":
        itemIndex = votingRoundReports.value.findIndex((votingRoundReport: ConferenceVotingRoundReport) =>
          votingRoundReport.id === path.id);
        if (~itemIndex) votingRoundReports.value.splice(itemIndex, 1);
        break;
      case "handRaiseOrchestrator":
        handRaiseStore.deleteHandRaiseOrchestrator(path);
        break;
      case "handRaiseVote":
        handRaiseStore.deleteHandRaiseVote(path);
        break;
    }
  }

  const fetchResult = async (path: ContentPath) => {
    const config = votingSessionStore.voting ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
        voting_round_report_id: path.id,
      }
    } : { params: { voting_round_report_id: path.id } };

    try {
      const response = await apiClient.get(`${sharedStore.electionUrl}/result`, config);
      const data = await response.data;
      if (response.status === 200) updateItem({ path: path, changes: data});
    } catch (error) {
      console.error(error);
    }
  }

  const fetchLiveResult = async (ballotId: number) => {
    try {
      const response = await apiClient.get(`${sharedStore.electionUrl}/live_result`, {
        params: {
          ballot_id: ballotId,
        }
      });
      const data = await response.data;
      if (response.status === 200) setResult(ballotId, data);
    } catch (error) {
      console.error(error);
    }
  }

  const ensureLastUpdateCounts = async (force: boolean = false) => {
    if (force) return;
    await apiClient.put(`${sharedStore.electionUrl}/ensure_update_counts`);
  }

  const goToSlide = (id: number | null) => {
    const newSlide = slides.value.find((slide: Slide) => slide.id === id);
    if (!newSlide) return activeSlideId.value = null;
    const oldSlide = slides.value.find((slide: Slide) => slide.id === activeSlideId.value);
    slideOffset.value = oldSlide ? oldSlide.position - newSlide.position : newSlide.position;
    activeSlideId.value = id;
  }

  const updateStatus = async (live: boolean = false) => {
    // app#status requires a voter session, observer#status does not, either can be accessed from this action
    const config = votingSessionStore.voting ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
      }
    } : {};
    
    const url = live ? `${sharedStore.electionUrl}/live_status` : `${sharedStore.electionUrl}/status`
    
    try {
      const response = await apiClient.get(url, config);
      const data = await response.data;
      if (votingSessionStore.voting) votingSessionStore.updateVoter(data.voter);
      sharedStore.setElection(data.election);

      if (data.activeSlide) {
        const activeIndex = data.slides.findIndex((slide: Slide) => slide.id === data.activeSlide.id);
        data.slides[activeIndex] = { ...data.slides[activeIndex], ...data.activeSlide };
      }

      setSlides(data.slides);
      setContests(data.contests);
      votingRounds.value = data.votingRounds;
      votingRoundReports.value = data.votingRoundReports;
      setVoterCounts(data.voterCounts);
      votingModulesStore.setActiveHighlight(data.activeHighlight);

      const includesHandraise = slides.value.some((slide: Slide) => slide.handRaise === true);

      if (includesHandraise) {
        const response = await apiClient.get(`${sharedStore.electionUrl}/hand_raise`)
        const data = await response.data;
        handRaiseStore.setHandRaiseOrchestrators(data.handRaiseOrchestrators);
      }

      if (data.activeSlide) goToSlide(data.activeSlide.id);
      else goToSlide(null);
      console.log("election updated");
    } catch (error) {
      console.error(error);
    }
  }

  const fetchLatestConfig = async () => {
    try {
      const response = await apiClient.get(`${sharedStore.election.boardUrl}/configuration/latest_config`);
      const data = await response.data;
      latestConfig.value = data;
    } catch (error) {
      console.error(error);
    }
  }

  const showToast = ({header, body, classes, duration = null}: Toast) => {
    toastId.value += 1;
    const index = toasts.value.findIndex((toast: Toast) => toast.id === toastId.value);
    if (index !== -1) toasts.value.splice(index, 1);

    const toast: Toast = {
      id: toastId.value,
      header,
      body,
      classes,
    }

    toasts.value.push(toast);

    if (duration) {
      setTimeout(() => { hideToast(toast) }, duration * 1000);
    }
  }

  const hideToast = (toast: Toast) => {
    const index = toasts.value.indexOf(toast);
    if (index !== -1) toasts.value.splice(index, 1);
  }

  const setVotingRound = (vr: ConferenceVotingRound) => {
    if (vr === null) return votingRound.value = null;
    votingRound.value = Object.assign({}, vr);
  }

  return {
    readonly,
    setReadonly,
    updateStatus,
    fetchResult,
    fetchLiveResult,
    latestConfig,
    fetchLatestConfig,
    subscribeToElectionChannel,
    activeSlide,
    setSlides,
    setVisibleTab,
    ensureLastUpdateCounts,
    goToSlide,
    setProgress,
    setResult,
    resetBallot,
    setVoterCounts,
    setBallotState,
    createItem,
    updateItem,
    deleteItem,
    slides,
    visibleTab,
    votingRounds,
    votingRound,
    setVotingRound,
    contests,
    showToast,
    hideToast,
    voterCounts,
    forceFullSizeSlide,
    contestsInActiveVotingRound,
    votingRoundReports,
    activeHandRaiseOrchestrator,
  }
}, {
  debounce: {
    ensureLastUpdateCounts: 3000,
  },
  persist: {
    storage: sessionStorage,
    paths: ["visibleTab"],
  },
});
